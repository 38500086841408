.news-content {
  width: 87%;
  padding-left: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.news-content p {
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-weight: 600;
  font-size: 0.839rem;
  line-height: 1.3rem;
}

.img-div {
  width: 13%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  }

.img-div img {
  object-fit: cover;
  border-radius: 8px;
  aspect-ratio: 1/1;
  width: 100%;
}